/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @description redux Reducers - Setting
 *
 * @version 20210302
 * @since 20210302 Initial release
 *
 */

import {
    SET_PRODUCT_INPUTS,
    RESET_PRODUCT,
} from "constants/product";
import { PRODUCTS } from "constants/products";
import { handleProductlInputsSPV } from "services/products/spv";
import { handleProductlInputsME } from "services/products/me";


function handleProductlInputs(data, product_id) {

    if (PRODUCTS.SPV.ID === product_id)
        return handleProductlInputsSPV(data?.details_inputs)

    if (PRODUCTS.ME.ID === product_id)
        return handleProductlInputsME(data)
}

const product = (state = null, action) => {

    switch (action.type) {
        case SET_PRODUCT_INPUTS: {
            let { product_id } = action.payload;

            return {
                TAG: action.payload?.p_tag,
                inputs: {
                    editProposalID: action.payload?.id,
                    ...handleProductlInputs(action?.payload, product_id)
                },
            };
        }
        case RESET_PRODUCT:
            return {
                TAG: null,
                inputs: {}
            }
        default:
            return state;
    }
};

export default product;