export const PRODUCTS = {
	SPV: {
		ID: 1,
		TAG: 'PVSOLAR',
		MODULES: {
			SIMPLES: 2
		}
	},
	ME: {
		ID: 2,
		TAG: 'EVCHARGERS',
		MODULES: {
			SIMPLES: 2
		}
	}
};